import React from 'react';
import axios from 'axios';
import LOADER_IMG from './loader.svg';
import style from './styles.css';

const Requisitions = (props) => {

  const container = document.createElement("div");
  container.setAttribute("class", 'requisition-loader');
  document.body.appendChild(container);

  const box = document.createElement("span");
  box.setAttribute("class", 'requisition-loader__loaderbox');
  container.appendChild(box);

  const loader = document.createElement("span");
  loader.setAttribute("class", 'requisition-loader__loader');
  box.appendChild(loader);

  function removeLoader(){
    var list = document.getElementsByClassName("requisition-loader");

    for(var i = list.length - 1; 0 <= i; i--){
      if(list[i] && list[i].parentElement){
        list[i].parentNode.removeChild(list[i]);
      }
    }
  }

  axios({
    mode: 'no-cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    credentials: 'same-origin',
    method: 'post',
    url: props.url,
    data: props.data
  })
  .then(function (response) {
    if(props.sucess){
      props.sucess(response.data);
    }
    removeLoader();
  })
  .catch(function (error) {
    removeLoader();
    if(props.error){
      props.error(error);
    }
  });
  // Post a user


}


export default Requisitions;
