import React, { useState, useEffect } from 'react';

import Select from 'react-select';

import FieldInput from './componentes/form/field-input';
import userIcon from './assets/images/user.png';
import cardIcon from './assets/images/card.png';

import AMEX from './assets/images/AMEX.svg';
import BILLET from './assets/images/BILLET.svg';
import DINERS from './assets/images/DINERS.svg';
import ELO from './assets/images/ELO.svg';
import HIPER from './assets/images/HIPER.svg';
import HIPERCARD from './assets/images/HIPERCARD.svg';
import MASTER_CARD from './assets/images/MASTER_CARD.svg';
import VISA from './assets/images/VISA.svg';
import AURA from './assets/images/aura.svg';
import JCB from './assets/images/jcb.svg';
import DISCOVER from './assets/images/discover.svg';

const meses = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' }
  ];

const anos = [];
for(let i = 2020; i< 2090; i++){
  anos.push({ value: i, label: i });
}

const tgdeveloper = {
  getCardFlag: function(_cardnumber) {
      const cardnumber = _cardnumber.replace(/[^0-9]+/g, '');

      const cards = {
          visa      : /^4[0-9]{12}(?:[0-9]{3})/,
          master : /^5[1-5][0-9]{14}/,
          diners    : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
          amex      : /^3[47][0-9]{13}/,
          discover  : /^6(?:011|5[0-9]{2})[0-9]{12}/,
          hipercard  : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
          elo        : /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
          jcb        : /^(?:2131|1800|35\d{3})\d{11}/,
          aura      : /^(5078\d{2})(\d{2})(\d{11})$/
      };

      for (var flag in cards) {
          if(cards[flag].test(cardnumber)) {
              return flag;
          }
      }

      return false;
  }

}

export default function (props) {
    const [brand, setBrand] = useState('');
    const [parcela, setParcela] = useState('');
    const [cartao, setCartao] = useState('');
    const [nome_cartao, setNome] = useState('');
    const [ano, setAno] = useState('');
    const [cvc, setCvc] = useState('');
    const [mes, setMes] = useState('');
    const { parcelas } = props;

    useEffect( () => {
      const _parcela = props.parcelas[0];
      setParcela(_parcela);
      props.onChangeValue({ value: _parcela, label: 'parcela' });
    }, [ parcelas ]);

    useEffect( () => {
      props.onChangeValue({ value: brand, label: 'bandeira' });
    }, [ brand ]);

    function selectBandeira(value, e) {
        setBrand(value);
        props.onChangeValue({ value, label: 'bandeira' });
        e.preventDefault();
    }

    function handleSelectChange(item) {
      setParcela(item);
      props.onChangeValue({ value: item, label: 'parcela' });
    }

    function convertNumber(e){
      const { target } = e;
      let { value } = target;
      value = value.replace(/[^0-9]+/g, '');
      return value;
    }

    function checkCartao(e) {
      const _n = convertNumber(e);
      setCartao(_n);
      const card = tgdeveloper.getCardFlag(_n);
      setBrand(card);
      props.onChangeValue({ value: _n, label: 'card' });

      if (brand) {
        props.onChangeValue({ value: brand, label: 'bandeira' });
      }
    }

    function handleSelectAnoChange(e) {
      setAno(e);
      props.onChangeValue({ value: e.value, label: 'ano' });
    }

    function cvcHandler(e) {
      const { target } = e;
      const { value } = target;
      if(value.length > 4) return false;
      const cvc = convertNumber(e);
      setCvc(cvc);
      props.onChangeValue({ value: cvc, label: 'cvc' });
    }

    function changeName(e) {
      const { target } = e;
      const { value } = target;
      setNome(value);
      props.onChangeValue({ value, label: 'nome_cartao' });
    }

    function handleSelectMesChange(e) {
      setMes(e);
      props.onChangeValue({ value: e.value, label: 'mes' });
    }

    return(
        <div>

        <div className="box-input inner">
            {/* <div className="box-input--title">Selecione a bandeira</div> */}
              <div>
                <div className="box-bandeiras" id="box-bandeiras">
                  <span>
                    <a
                      className={brand==='visa' ? 'ativo' : ''}
                      href='visa'
                      onClick={(e) => selectBandeira('visa',e)}>
                      <img alt='Visa' src={VISA} />
                    </a>
                  </span>
                  <span>
                    <a
                      className={brand==='master' ? 'ativo' : ''}
                      href='master'
                      onClick={(e) => selectBandeira('master',e)}>
                      <img alt='Mastercard' src={MASTER_CARD} />
                    </a>
                  </span>
                  <span>
                    <a
                      className={brand==='amex' ? 'ativo' : ''}
                      href='amex'
                      onClick={(e) => selectBandeira('amex',e)}>
                      <img alt='Amex' src={AMEX} />
                    </a>
                  </span>
                  <span>
                    <a
                      className={brand==='elo' ? 'ativo' : ''}
                      href='elo'
                      onClick={(e) => selectBandeira('elo',e)}>
                      <img alt='Elo' src={ELO} />
                  </a>
                  </span>
                  {/*
                    <span>
                      <a
                        className={brand==='Aura' ? 'ativo' : ''}
                        href='Aura'
                        onClick={(e) => selectBandeira('Aura',e)}>
                        <img src={AURA} />
                    </a>
                    </span>
                    <span>
                      <a
                        className={brand==='JCB' ? 'ativo' : ''}
                        href='JCB'
                        onClick={(e) => selectBandeira('JCB',e)}>
                        <img src={JCB} />
                    </a>
                    </span>
                  */}
                  <span>
                    <a
                      className={brand==='diners' ? 'ativo' : ''}
                      href='diners'
                      onClick={(e) => selectBandeira('diners',e)}>
                      <img alt='Dinners' src={DINERS} />
                  </a>
                  </span>
                  {/*
                    <span>
                      <a
                        className={brand==='Discover' ? 'ativo' : ''}
                        href='Discover'
                        onClick={(e) => selectBandeira('Discover',e)}>
                        <img src={DISCOVER} />
                    </a>
                    </span>

                  */}
                  <span>
                    <a
                      className={brand==='hipercard' ? 'ativo' : ''}
                      href='hipercard'
                      onClick={(e) => selectBandeira('hipercard',e)}>
                      <img alt='Hipercard' src={HIPERCARD} />
                  </a>
                  </span>
                  <input type='hidden' value={brand} name='brand' id="input-brand" />
                </div>
              </div>
            </div>

            <div className="inner">
                <FieldInput onChange={(e) => checkCartao(e)} value={cartao} icon={cardIcon} placeholder='Número do cartão' name='cartao' />
            </div>
            <div className="inner">
            <FieldInput onChange={changeName} value={nome_cartao} icon={userIcon} placeholder='Nome do titular do cartão' name='nome_cartao' />
            </div>
            <div className='group'>
            <div className="col-3">
                <div className="inner">
                  <div className='field'>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="mes"
                      options={meses}
                      placeholder='Mês'
                      onChange={handleSelectMesChange}
                      value={mes}
                      />
                  </div>
                </div>
            </div>
            <div className="col-3">
                <div className="inner">
                  <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="ano"
                      options={anos}
                      placeholder='Ano'
                      onChange={handleSelectAnoChange}
                      value={ano}
                      />
                </div>
            </div>
            <div className="col-3">
                <div className="inner">
                  <div className='box-cvc'>
                    <FieldInput onChange={cvcHandler} value={cvc} placeholder='CVC' name='cvc' />
                    <span className='cvc-desc tooltip'>?
                      <span className="tooltiptext">3 ou 4 dígitos, geralmente ficam atrás do cartão</span>
                    </span>
                  </div>
                </div>
            </div>
            </div>
            <div className="inner">
            <Select
                className="basic-single"
                classNamePrefix="select"
                name="parcela"
                options={props.parcelas}
                placeholder='Número de parcelas'
                value={parcela}
                onChange={handleSelectChange}
              />
            </div>
        </div>
    );
}