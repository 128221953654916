import React from 'react';



import './assets/sass/checkout.scss';
import logo from './assets/images/logo.svg';
import Sidebar from './Sidebar';
import Form from './Form';

function App() {
  return (
    <div className="App">
      <header className="header-brand">
        <img alt="Logo Foco90" src={logo} />
      </header>
      <section id="content">
        <Form />
        <Sidebar />
      </section>
    </div>
  );
}

export default App;
