import React from 'react';
import Select from 'react-select';
import { cpf as validaCPF } from 'cpf-cnpj-validator'; 
import ReactPixel from 'react-facebook-pixel';


import userIcon from './assets/images/user.png';
import cadeadoIcon from './assets/images/cadeado.png';
import telefoneIcon from './assets/images/telefone.png';
import emailIcon from './assets/images/email.png';
import Dialog from './components/alert/Dialog';
import WS from './config/Ws';
import Requisitions from './utils/requisitions/Requisitions';
import buttonSubmit from './assets/images/bt.png';
import FieldInput from './componentes/form/field-input';
import FormaPagamento from './FormaPagamento';
import Termos from './Termos';
import ESTADOS from './config/estados';
import FormEndereco from './FormEndereco';
import inputHandler from './utils/inputHandler';
import {submitHandler} from './SubmitHandler';
import { emailIsValid } from './utils/validations/email';
import { validaPhone } from './utils/validations/phone';


const TEL_MASK = ['(99) 9999-99999', '(99) 99999-9999'];
const CPF_MASK = ['999.999.999-99'];

const FILLUP = false;
const CPF = FILLUP ? '11343233798' : '';
const NOME = FILLUP ? 'Jhonnatan Soares Rebuli' : '';
const EMAIL = FILLUP ? 'jhonnatan@gmail.com' : '';
const SENHA = FILLUP ? '1' : '';
const TELEFONE = FILLUP ? '27997691749' : '';

const FB_OPT = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

export default class Form extends React.Component {
  constructor(){
    super();

    const planoId = this.getUrlVars()["plano"];

    this.state = {
      planos: [],
      planoId,
      plano: null,
      parcelasOpt: [],
      cpf_cnpj: CPF,
      nome: NOME,
      email: EMAIL,
      senha: SENHA,
      confirmacao_senha: SENHA,
      telefone: TELEFONE
    };

    this.changeTermos = this.changeTermos.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.callAlert = this.callAlert.bind(this);

  }

  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
    function(m,key,value) {
      vars[key] = value;
    });
    return vars;
  }

  onBlur(e){
    const { target } = e;
    const { name, value } = target;
    
    if(value && name === 'email' && !emailIsValid(value)) {
      this.callAlert('Digite um email válido');
    }

    if(value && name === 'cpf_cnpj' && !validaCPF.isValid(value)) {
      this.callAlert('Digite um cpf válido');
    }

    if(value && name === 'telefone' && !validaPhone(value)) {
      this.callAlert('Digite um telefone válido');
    }

  }

  onChangeValue(args) {
    const { label, value } = args;
    this.setState({
      [label]: value
    });
  }

  onChangeHandler(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name === 'email'){
      value = value.replace(/\s+/g,"");
      value = value.replace(/[˜§€±`~!#$%^&*()|+\=÷¿?;:'",<>\{\}\[\]\\\/]/gi, '');
    }

    if(name === 'cpf_cnpj') {
      value = inputHandler(value, CPF_MASK, 14);
    }

    if(name === 'telefone'){
      value = inputHandler(value, TEL_MASK, 14);
    }

    this.setState({
      [name]: value
    });
  }

  callAlert(msg) {
      Dialog.create({
          title: 'Atenção',
          text: msg
      });
  }

  fillCep(data) {
    const {
      bairro,
      logradouro,
      localidade,
      uf
    } = data;
    const _uf = ESTADOS.filter(i => i.value === uf);

    this.setState({
      cidade:localidade,
      bairro,
      logradouro,
      uf: _uf.length ? _uf[0] : null
    });
  }

  callCep(cep) {
    const self = this;

    Requisitions({
      url: WS.cep,
      data: {cep},
      sucess:function(data){
        if(data.status){
          self.fillCep(data);
        }else{
          var msg = {
            title: 'Atenção',
            text: data.msg
          };
          Dialog.create(msg);
        }

      },
      error:function(data){
        Dialog.create({
          title: 'Atenção',
          text: 'Ocorreu um erro ao retornar os planos. Por favor, recarregue a página novamente!'
        });
      }
    });
  }
 
  handleSelectChange(value){
    const n_parcelas = value.max_parcelas;
    const valor = value.valor.replace(',','.');
    const parcelas = [];

    for(let i = 0; i < n_parcelas; i++){
      const parc = (valor/(i+1)).toFixed(2);
      parcelas.push({
        label: `${i+1}x de R$${parc}`,
        value: (i+1)
      });
    }

    this.setState({
      plano: value,
      planoPeriodicidade: value.periodicidadeDesc,
      planoValue: `R$${value.valor}`,
      planoDescricao: value.descricao_completa,
      parcelasOpt: parcelas
    });
  }

  componentDidMount() {
    const self = this;

    ReactPixel.init('525728409120997', {}, FB_OPT);
 
    //ReactPixel.pageView();
    ReactPixel.track('InitiateCheckout', this.state.planoId ? {plano:this.state.planoId} : {});

    Requisitions({
      url: WS.planos,
      data: {},
      sucess:function(data){
        if(data.status){
          self.setState({ planos: self.getPlanos(data.planos, 'ativos'), _planos: self.getPlanos(data.planos) }, () => {
            //caso aja planoId quer dizer que o cliente veio do site e escolheu um plano qualquer
            //aqui devemos já ter o plano selecionado
            if(self.state.planoId){
              self.changePlano();
            }
          });          
        }else{
          var msg = {
            title: 'Atenção',
            text: data.msg
          };
          Dialog.create(msg);
        }

      },
      error:function(data){
        Dialog.create({
          title: 'Atenção',
          text: 'Ocorreu um erro ao retornar os planos. Por favor, recarregue a página novamente!'
        });
      }
    });
  }

  getPlanos(planos, status) {
    return planos.filter(i => {
      i.label = `${i.nome} - R$${i.valor}`;
      i.value = i.id;
      if (status) {
        if(i.ativo){
          return i;
        }
        return null;
      }else{
        return i;
      }
    });
  }

  changePlano() {
    const { planoId, _planos } = this.state;
    const plano = _planos.filter( i => String(i.id) === String(planoId));

    if(plano.length)
      this.handleSelectChange(plano[0]);
  }
  
  changeTermos(url) {
    this.setState({
      urlTermos: url
    });
  }

  render() {
    const {
      plano,
      planos,
      urlTermos,
      planoPeriodicidade,
      planoValue,
      planoDescricao,
      parcelasOpt,
      nome,
      cpf_cnpj,
      telefone,
      email,
      senha,
      confirmacao_senha
    } = this.state;

    return(
      <div id="content__main" autoComplete='off'>
        {
            urlTermos ? <Termos onClose={()=>this.changeTermos(null)} url={urlTermos} /> : null
        }
        <div id="content__inner">
          <div className="wrapper inner">
            <h6 className="title">Selecione seu plano</h6>
            <div className="select-container">
              <div className="select-container__select">
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="color"
                    options={planos}
                    placeholder='Selecione seu plano'
                    onChange={this.handleSelectChange}
                    value={plano}
                  />
              </div>
              <div className="select-container__price">
                <strong className="select-container__value">{planoValue}</strong>
                <span className="select-container__periodity">{planoPeriodicidade}</span>
              </div>
            </div>
            {
              planoDescricao 
              ? <div className="plan__description">
                  {planoDescricao}
                </div>
              : null
            }
            
          </div>
          <div className="wrapper">
            <h6 className="title inner">Dados gerais</h6>
            <div className="inner">
              <FieldInput onChange={this.onChangeHandler} value={nome} icon={userIcon} placeholder='Seu nome completo' name='nome' />
            </div>
            <div className='group'>
              <div className="col-5">
                <div className="inner">
                  <FieldInput onBlur={this.onBlur} onChange={this.onChangeHandler} value={cpf_cnpj} icon={cadeadoIcon} placeholder='Seu CPF' name='cpf_cnpj' />
                </div>
              </div>
              <div className="col-5">
                <div className="inner">
                  <FieldInput onBlur={this.onBlur} onChange={this.onChangeHandler} value={telefone} icon={telefoneIcon} placeholder='Seu Telefone' name='telefone' />
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <h6 className="title inner">Dados de acesso</h6>
            <div className="inner">
              <FieldInput onBlur={this.onBlur} onChange={this.onChangeHandler} value={email} icon={emailIcon} placeholder='Seu email' name='email' />
            </div>
            <div className='group'>
              <div className="col-5">
                <div className="inner">
                  <FieldInput onChange={this.onChangeHandler} value={senha} type="password" icon={cadeadoIcon} placeholder='Crie sua senha de acesso' name='senha' />
                </div>
              </div>
              <div className="col-5">
                <div className="inner">
                  <FieldInput onChange={this.onChangeHandler} value={confirmacao_senha} type="password" icon={cadeadoIcon} placeholder='Confirmação de senha' name='confirmacao_senha' />
                </div>
              </div>
            </div>
          </div>
          {/* <FormEndereco /> */}
          <FormaPagamento plano={this.state.plano} parcelas={parcelasOpt} onChangeValue={this.onChangeValue} />

          <div className='inner'>
              <div className="li_concordo">
                  <span className="type-pay-txt">Ao prosseguir, você concorda com nossos <button onClick={()=>this.changeTermos("https://foco90.com.br/termos/?app=true")}>termos de uso e faturamento</button> e <button onClick={() => this.changeTermos("https://foco90.com.br/politica-privacidade/?app=true")}>políticas de privacidade</button></span>
              </div>
          </div>
        </div>
        
        <div className="bx-bt-enviar">
          <button onClick={() => submitHandler(this.state)}>
            <img className="img-bt-submit" src={buttonSubmit} alt="Botão enviar" />
          </button>
        </div>
      </div>
    );
  }
}