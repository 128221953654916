import Random from '../../utils/strings/Random';
import React from 'react';
import ReactDOM from 'react-dom';
import styles from './dialog.css';


const CLASS_WRAPPER = '__react-dialog-wrapper__';

class Dialog extends React.Component{
  constructor (props){
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      type: props.dados.type ? props.dados.type : Dialog.SIMPLE ,
      onCancel: props.dados.onCancel ? props.dados.onCancel : this.onCancel ,
      onSubmit: props.dados.onSubmit ? props.dados.onSubmit : this.onSubmit,
      txtCancel: props.dados.txtCancel ? props.dados.txtCancel : 'Cancel',
      txtOk: props.dados.txtOk ? props.dados.txtOk : 'OK',
    }
  }

  static SIMPLE = 1;
  static CONFIRM = 2;

  onCancel (e) {
    this.remove();
    e.preventDefault();
  }

  onSubmit (e) {
    this.remove();
    e.preventDefault();
  }

  remove(){
    let _el = document.getElementById(this.props.dados.identifier);
    Dialog.removeDialog(_el);
  }

  static removeDialog(_el){
    _el.classList.remove('in');
    //delay para criar animação no elemento
    setTimeout(function(){
      ReactDOM.unmountComponentAtNode(_el);
      _el.remove();
    },300);
  }

  static destroy (){
    let boxes = document.getElementsByClassName(CLASS_WRAPPER);
    if(boxes){
      let el = boxes[0];
      let id = el.getAttribute('id');
      let _el = document.getElementById(id);
      Dialog.removeDialog(_el);
    }
  }

  static create (__args__ = {}){
    const container = document.createElement("div");
    var _randomId = Random(5);
    container.setAttribute("id", _randomId);
    container.setAttribute("class", CLASS_WRAPPER);
    document.body.appendChild(container);
    __args__.identifier = _randomId;

    var overlay = ReactDOM.render(<Dialog dados={__args__} />,document.getElementById(_randomId));

    //delay para criar animação no elemento
    setTimeout(function(){
      container.classList.add('in');
    },10);

    return _randomId;
  }

  render() {

    var buttons;

    switch (this.state.type){
      case Dialog.SIMPLE:
      buttons = <div className="dialog-buttons">
                  <a onClick={this.state.onSubmit} className="dialog-button dialog-button-bold">{this.state.txtOk}</a>
                </div>;
        break;
      case Dialog.CONFIRM:
        buttons = <div className="dialog-buttons">
                    <a onClick={this.state.onCancel} className="dialog-button">{this.state.txtCancel}</a>
                    <a onClick={this.state.onSubmit} className="dialog-button dialog-button-bold">{this.state.txtOk}</a>
                  </div>;
        break;
    }

    return (
      <div className='__react-dialog__'>
        <div className="dialog dialog-buttons-2">
          <div className="dialog-inner">
            <div className="dialog-title">{this.props.dados.title}</div>
            <div className="dialog-text">{this.props.dados.text}</div>
          </div>
          <div>
              { buttons }
          </div>
        </div>
      </div>
    )
  };
}

export default Dialog;
