/**
  * retorna um valor randômico de acordo com o @length
*/
function Random (length) {
  var length = length ? length : 5;
  var str = '';
  var chars ='abcdefghiklmnopqrstuvwxyz'.split(
      '');
  var charsLen = chars.length;
  if (!length) {
    length = ~~(Math.random() * charsLen);
  }
  for (var i = 0; i < length; i++) {
    str += chars[~~(Math.random() * charsLen)];
  }
  return str;
}

export default Random;
