import React from 'react';

export default function Termos(props) {
    return(
        <div className='termo-wrapper'>
            <div className='termo-box'>
                <div className='header-termos'>
                    <a className='bt-close-termos' onClick={props.onClose}>Fechar</a>
                </div>
                <iframe src={props.url} title='Termos e Políticas de privacidade' />
            </div>
        </div>
    );
}