import React from 'react';

import userIcon from './assets/images/user.png';
import emailIcon from './assets/images/email.png';
import FieldInput from './componentes/form/field-input';

export default function() {
    return(
        <div>
            <h6>Fique atento(a) aos detalhes:</h6>
            <ul className="list-boleto">
                <li>Pagamento por Boleto Bancário leva <strong>1 dia útil</strong> para ser compensado e então liberar seu acesso</li>
                <li>Fique atento(a) ao vencimento do boleto. Você pode pagar o boleto em qualquer banco ou casa lotérica até o dia do vencimento</li>
                <li>Depois do pagamento, fique atento ao seu e-mail para receber os dados de acesso ao produto (verifique também a caixa de SPAM)</li>
            </ul>
            {/* <p className='title-boleto'>Preencha os dados abaixo para gerar seu boleto</p>
            <div className=''>
                <div className="inner">
                    <FieldInput icon={userIcon} placeholder='Seu nome completo' name='nome' />
                </div>
                <div className="inner">
                    <FieldInput icon={emailIcon} placeholder='Seu email' name='email' />
                </div>
            </div> */}
        </div>
    );
}