import React from 'react';

import './field-input.scss';

export default function(props) {
    return (
        <div className="field">
            <div className="field__inner">
            <div className="field__content">
                {
                    props.icon ? 
                    <div className="field__icon">
                        <img src={props.icon} alt="Ícone" />
                    </div>
                    : null
                }
                
                <div className="field__input-box">
                    <input autoComplete="off" {...props} className="field__input" onChange={props.onChange} />
                </div>
            </div>
            </div>
        </div>
    );
}