import VMasker from 'vanilla-masker';

export default function inputHandler(value, masks, max) {
    var m = 0;
    var v = VMasker.toNumber(value);
      v = value.replace(/\D/g, '');
    v = VMasker.toPattern(v, masks[m]);
      m = v.length > max ? 1 : 0;
    v = VMasker.toPattern(v, masks[m]);
      return v;
  }