var host = window.location.href;
var HOST;

//desenvolvimento jhon
if(host.indexOf("http://localhost:3000/") > -1 ||
host.indexOf("http://localhost:3001/") > -1 )
{
  host = "http://localhost/foco90/v4/Foco90/api/ws-temp/app/web-services.php";
  HOST = "http://localhost/foco90/sistema/Foco90-api/";
}
//server
else{
  host = "https://api.foco90.com.br/";
  HOST = "https://api.foco90.com.br/";
}
HOST = "https://api.foco90.com.br/";
// HOST = "http://localhost/foco90/sistema/Foco90-api/";
/*
  ------------------------------------------
  WS
  ------------------------------------------
*/
const WS = {
  planos: `${HOST}payment/planos/load/`,
  aderir: `${HOST}payment/pagamento/aderir/`
};

export default WS;
