function validaPhone(phone) {
    phone = phone.replace(/[^0-9]+/g, '');
    if(phone.length === 10){
        phone = String(phone);
        if( 
            phone === "0000000000" ||
            phone === "1111111111" ||
            phone === "2222222222" ||
            phone === "3333333333" ||
            phone === "4444444444" ||
            phone === "5555555555" ||
            phone === "6666666666" ||
            phone === "7777777777" ||
            phone === "8888888888" ||
            phone === "9999999999"
        ) {
            return false;
        }
    } else if(phone.length === 11){
        phone = String(phone);
        if( 
            phone === "00000000000" ||
            phone === "11111111111" ||
            phone === "22222222222" ||
            phone === "33333333333" ||
            phone === "44444444444" ||
            phone === "55555555555" ||
            phone === "66666666666" ||
            phone === "77777777777" ||
            phone === "88888888888" ||
            phone === "99999999999"
        ) {
            return false;
        }
    } else {
        return false;
    }
    return true;
}

export { validaPhone };