import React from 'react';
import './styles.scss';

const Cliente = props => {
    return (
        <div className="cliente">
            <img alt={props.name} src={props.src} />
            <span className="cliente__name">{props.name}</span>
        </div>
    );
}

export default Cliente;