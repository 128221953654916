import React from 'react';

import Cliente from './componentes/cliente';
import Priscila from './assets/images/priscila.jpg';
import Stella from './assets/images/stella.jpg';
import Lindines from './assets/images/lindines-lima.jpg';

export default function Sidebar(){
    return (
        <div id="contend__sidebar">
          <div id="contend__sidebar-inner">
            <Cliente name="Priscila Domingos" src={Priscila} />
            <Cliente name="Stella" src={Stella} />
            <Cliente name="Lindines Lima" src={Lindines} />
            <span style={{color: '#ffffff', fontSize: 10}}>V1.0.0.2</span>
          </div>
        </div>
    );
}