import React from 'react';
import Select from 'react-select';

import inputHandler from './utils/inputHandler';
import ESTADOS from './config/estados';
import FieldInput from './componentes/form/field-input';
import Requisitions from './utils/requisitions/Requisitions';
import WS from './config/Ws';
import Dialog from './components/alert/Dialog';

const CEP_MASK = ['99999-999'];

export default class FormEndereco extends React.Component {
    constructor() {
        super();
        this.state = {};
        this.callCep = this.callCep.bind(this);
        this.handleSelectEstadoChange = this.handleSelectEstadoChange.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

  onChangeHandler(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name === 'cep') {
      value = inputHandler(value, CEP_MASK, 10);
      if(value.length === 9){
        this.callCep(value);
      }
    }

    this.setState({
      [name]: value
    });
  }

  fillCep(data) {
    const {
      bairro,
      logradouro,
      localidade,
      uf
    } = data;
    const _uf = ESTADOS.filter(i => i.value === uf);

    this.setState({
      cidade:localidade,
      bairro,
      logradouro,
      uf: _uf.length ? _uf[0] : null
    });
  }

  callCep(cep) {
    const self = this;

    Requisitions({
      url: WS.cep,
      data: {cep},
      sucess:function(data){
        if(data.status){
          self.fillCep(data);
        }else{
          var msg = {
            title: 'Atenção',
            text: data.msg
          };
          Dialog.create(msg);
        }
      },
      error:function(data){
        Dialog.create({
          title: 'Atenção',
          text: 'Ocorreu um erro ao retornar os planos. Por favor, recarregue a página novamente!'
        });
      }
    });
  }

    handleSelectEstadoChange(uf) {
        this.setState({ uf });
    }

    render(){
        const {
            cep,
            cidade,
            bairro,
            logradouro,
            complemento,
            numero,
            uf
        } = this.state;
        return(
            <div className="wrapper">
                <h6 className="title inner">Endereço</h6>
                <div className="inner">
                  <div className='col-3'>
                    <FieldInput onChange={this.onChangeHandler} value={cep} placeholder='Digite seu CEP' name='cep' />
                  </div>
                </div>
                <div className='group'>
                  <div className="col-5">
                    <div className="inner">
                      <div className='field'>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="uf"
                            options={ESTADOS}
                            placeholder='Estado'
                            value={uf}
                            onChange={this.handleSelectEstadoChange}
                          />
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="inner">
                      <FieldInput onChange={this.onChangeHandler} value={cidade} placeholder='Cidade' name='cidade' />
                    </div>
                  </div>
                </div>
                <div className='group'>
                  <div className="col-5">
                    <div className="inner">
                      <FieldInput onChange={this.onChangeHandler} value={bairro} placeholder='Bairro' name='bairro' />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="inner">
                      <FieldInput onChange={this.onChangeHandler} value={logradouro} placeholder='Logradouro' name='logradouro' />
                    </div>
                  </div>
                </div>
                <div className='group'>
                  <div className="col-5">
                    <div className="inner">
                      <FieldInput onChange={this.onChangeHandler} value={complemento} placeholder='Complemento' name='complemento' />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="inner">
                      <FieldInput onChange={this.onChangeHandler} value={numero} placeholder='Número' name='numero' />
                    </div>
                  </div>
                </div>
              </div>
        );
    }
}